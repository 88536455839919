import React, { useState } from "react"
import styled from "styled-components"
import PrimaryLayout from "@src/layouts/primary"
import { testimonialPageData } from "@src/utils/constants/video-testimonials"
import ResponsiveSection from "@src/components/container-responsive-section"
import { why10ATestimonial } from "@src/utils/constants/video-testimonials"
import YoutubeModal from "@src/components/youtube-player-modal"
import VideoTestimonialTile from "@src/components/content-social-proof-video-testimonial-tile"
import BreadcrumbsV2 from "@src/components/nav-content-breadcrumbs-v2"
import PageHelmet from "@src/components/page-helmet"
import { generateMeta } from "@src/utils/page-data"

const StyledSection = styled(ResponsiveSection)`
  background: #fbf8f5;
  justify-items: center;
  padding: 24px 0px;
  box-shadow: inset 0px 7px 3px -3px rgb(246 246 246);
  & .breadcrumbs {
    justify-self: start;
  }
  & > h1 {
    font-family: system-ui;
    font-size: 46px;
    font-weight: 900;
    line-height: 64px;
    letter-spacing: 0.5px;
    text-align: center;
    margin-bottom: 24px;
  }
  & > .testimonial-header {
    background: #757575;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${props => props.theme.white};
    padding: 30px 24px;
    border-radius: 16px;
    margin-bottom: 60px;
    & > .testimonial-header-text {
      & > h2 {
        font-family: system-ui;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0px;
        text-align: left;
      }
      & > p {
        font-family: system-ui;
        font-family: system-ui;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.5px;
        text-align: left;
      }
    }
  }
  & > .testimonials-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    & > a {
      display: inline-block;
    }
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    & > .testimonial-header {
      flex-direction: row;
      padding: 30px 48px;
      & > .testimonial-header-text {
        margin-right: 24px;
      }
    }
  }
  @media only screen and (min-width: 860px) {
    & > .testimonials-container {
      display: block;
      width: 800px;
      columns: 2;
      column-width: 384px;
      & > a {
        margin: 12px;
      }
    }
  }
  @media only screen and (${props => props.theme.screen.large.min}) {
    & > .testimonials-container {
      columns: 3;
      width: 1210px;
      & > a {
        margin: 10px;
      }
    }
  }
  @media only screen and (${props => props.theme.screen.medium.min}) {
    & > .testimonial-header {
      & > .testimonial-header-text {
        margin-right: 144px;
      }
    }
  }
`
const TestimonialsPage = () => {
  const [showVideo, setShowVideo] = useState(false)
  const handleVideoClose = () => setShowVideo(false)
  const imageSizes = {
    portrait: ["384x455", "768x910"],
    landscape: ["384x221", "768x442"],
  }
  return (
    <PrimaryLayout>
      <PageHelmet
        title="10Adventures Reviews"
        meta={generateMeta("/testimonials/", {
          metaDesc:
            "Watch reviews for 10Adventures. See what it's like to book a vacation with 10Adventures. Check out the videos for 10Adventures Reviews.",
          metaRobotsNoindex: "index",
        })}
      />
      <StyledSection>
        <BreadcrumbsV2
          breadcrumbItems={[
            { text: "Home", link: "/" },
            { text: "Testimonials", link: "/testimonials/" },
          ]}
        />
        <h1>The 10Adventures Experience</h1>
        <div className="testimonial-header">
          <div className="testimonial-header-text">
            <h2>Happy Travellers, Great Adventures</h2>
            <p>
              {`There's no better way to get excited for an upcoming adventure than by hearing from our past travellers about the lifelong memories they've made on trips with 10Adventures. Tune in to this short video to discover why the 10Adventures experience is different, and how we can make your travel dreams a reality!`}
            </p>
          </div>
          <VideoTestimonialTile
            variant="why10A"
            image={{ ...why10ATestimonial.image }}
            url={why10ATestimonial.url}
          />
        </div>

        <div className="testimonials-container">
          {testimonialPageData
            .filter(data => data.url)
            .map(({ name, url, tour, quote, imageFolder }, index) => {
              const layout = index % 2 ? "portrait" : "landscape"
              const imageURLs = imageSizes[layout].map(size =>
                encodeURI(`${imageFolder}${size}.jpg`)
              )
              const testimonial = {
                name,
                url,
                tour,
                quote,
                variant: layout,
                image: {
                  src: imageURLs[0],
                  alt: `${name}-photo`,
                  srcSet: `${imageURLs[0]} 1x, ${imageURLs[1]} 2x`,
                  // Lazyload set to false as non-curated photos show at the top based on browser CSS logic
                  lazyload: false,
                },
              }
              return (
                <VideoTestimonialTile key={testimonial.name} {...testimonial} />
              )
            })}
        </div>
        {showVideo ? (
          <YoutubeModal
            id="why10a-video"
            isOpen={showVideo}
            onClose={handleVideoClose}
            videoId={why10ATestimonial.url.slice(-11)}
          />
        ) : null}
      </StyledSection>
    </PrimaryLayout>
  )
}

export default TestimonialsPage
